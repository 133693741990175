.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ****************************************************************************************************/

/* Login画面 (sign in と sign up) のボタン色 */

/* ref. https://ui.docs.amplify.aws/react/components/button#target-classes */

/* サインインボタンの幅を変更する */
.amplify-button {
  background-color: red; /* 「.amplify-button」クラスのボタンを 赤色 にする */
  border-color: darkred;
  width: 80%; /* ここで希望の幅のパーセンテージを指定 */
  margin-left: auto; /* 中央寄せのための設定 */
  margin-right: auto; /* 中央寄せのための設定 */
}

/* ****************************************************************************************************/

/* F12 → 開発ツール → Login画面のクラスを特定する → data-amplify-form と判明する */

/* Login画面幅(width)の変更 */
div[data-amplify-router] {
  width: 80%;
  margin-left: auto; /* 中央寄せのための設定 */
  margin-right: auto; /* 中央寄せのための設定 */
}

/* ユーザー名やパスワードフォームの幅を変更する */
form[data-amplify-form] {
  width: 100%; /* ここで希望の幅を指定 */
  margin: 0 auto; /* センタリングのためのスタイル */
}

/* ユーザー名やパスワードの入力フィールドの幅を変更する */
.amplify-input {
  width: 100%; /* ここで希望の幅のパーセンテージを指定 */
}

/* 「パスワード表示」(目のアイコン) のボタンを調整する */
.amplify-button[aria-label="Show password"] {
  width: 100%; /* ここで希望の幅を指定 */
  background-color: #00f; /* 背景色を 青色 にする */
}

/* 「パスワード」のデフォルト(目のアイコン)を表示しないようにする */
input::-ms-reveal {
  display: none;
}

/* 「Forgot your password?」ボタンの幅を変更する */

/* 「.amplify-button」クラスだが、特別に色を変更する */
div[data-amplify-footer] .amplify-button {
  background-color: yellow; /* 背景色を黄色に変更 */
  border-color: gold; /* 枠線の色をgoldに変更 */
}

/* ****************************************************************************************************/

/* App.css */
.forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.button-group {
  gap: 10px;
}

/* アイコンの位置指定 */
.material-icons {
  font-size: 24px;
  color: gray;
  padding-top: 10px;
}

/* 入力エリアのdiv */
.input-div {
  display: flex;
  align-items: center;
  height: 74px;
}

/* Email or UserID form */
.text-userfield {
  width: 300px;
  height: 45px;
  margin: 0 0 0 10px;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 16px;
  padding-top: 10px;
  opacity: 0.8;
}

/* パスワード input form */
.text-passfield {
  width: 270px;
  height: 45px;
  margin: 0 10px;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 16px;
  padding-top: 10px;
  opacity: 0.8;
}

/* ロゴ */
.header-logo {
  margin: 25px 0 0;
}

/* ブルーのボタン */
.bule-button {
  width: 300px;
  height: 30px;
  background-color: rgba(33 150 243);
  color: white;
  border: none;
  font-size: 0.875rem;
  border-radius: 4px;
  box-shadow:
    0 3px 1px -2px rgba(0 0 0 0.2),
    0 2px 2px 0 rgba(0 0 0 0.14),
    0 1px 5px 0 rgba(0 0 0 0.12);
}

.error-div {
  color: red;
  font-size: 13px;
}

.not-found-title {
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 30px;
}

.not-found-sub {
  font-size: x-large;
  padding-bottom: 20px;
}

/* 多言語対応ヘッダー */

/* ****************************************************************************************************/
.header-auth {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 30px;
}

.language-div {
  display: flex;
}

.select-language {
  text-align: center;
  font-size: calc(10px + 1vmin);
  height: 50px;
}

/* ****************************************************************************************************/
